<template>
  <auth-layout>
    <page-section
      class="container mx-auto max-w-lg flex-1 flex flex-col items-center px-4"
    >
      <v-card class="px-4 py-3 text-center mt-12">
        <v-card-body>
          <h2 class="text-3xl font-bold">Inregistrare</h2>
          <h4 class="mb-4 text-red-500 text-left">{{ message }}</h4>

          <form
            class="mb-4"
            action="/register"
            method="POST"
            @submit.prevent="onSubmit"
            @keydown="form.errors.clear($event.target.name)"
          >
            <div class="text-left mb-4">
              <v-label for="name">Name</v-label>
              <v-input type="text" id="name" name="name" v-model="form.name" />
              <v-error :error="form.errors.get('email')"></v-error>
            </div>
            <div class="text-left mb-4">
              <v-label for="email">Email </v-label>
              <v-input
                type="text"
                id="email"
                name="email"
                autocomplete="email"
                v-model="form.email"
              />
              <v-error :error="form.errors.get('email')"></v-error>
            </div>
            <div class="text-left mb-4">
              <v-label for="password">Parola </v-label>
              <v-input
                type="password"
                id="password"
                name="password"
                autocomplete="current-password"
                v-model="form.password"
              />
              <v-error :error="form.errors.get('password')"></v-error>
            </div>
            <div class="text-left mb-4">
              <v-label for="password_confirmation">Confirma parola </v-label>
              <v-input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                v-model="form.password_confirmation"
              />
              <v-error
                :error="form.errors.get('password_confirmation')"
              ></v-error>
            </div>

            <v-button-primary :disabled="form.loading">
              <v-button-icon v-if="form.loading">
                <loader :radius="5" />
              </v-button-icon>
              Continua
            </v-button-primary>
          </form>

          <router-link :to="{ name: 'login' }">
            <span
              class="
                text-sm text-gray-500
                hover:text-gray-700
                border-b border-transparent
                hover:border-gray-700 hover:border-current
              "
            >
              Ai deja cont? Conecteaza-te
            </span>
          </router-link>
        </v-card-body>
      </v-card>
    </page-section>
  </auth-layout>
</template>

<script>
import VInput from "../components/VInput.vue";
import VLabel from "../components/VLabel.vue";
import VError from "../components/VError.vue";
import PageSection from "../components/PageSection.vue";
import Form from "@/core/Form";

import AuthLayout from "../layout/AuthLayout.vue";
import authService from "@/services/authService";
import VCardBody from "../components/VCardBody.vue";
import VCard from "../components/VCard.vue";
import VButtonPrimary from "../components/VButtonPrimary.vue";
import VButtonIcon from "../components/VButtonIcon.vue";
import Loader from "../components/Loader.vue";

export default {
  components: {
    VLabel,
    VInput,
    VError,
    PageSection,
    AuthLayout,
    VCardBody,
    VCard,
    VButtonPrimary,
    VButtonIcon,
    Loader,
  },

  data() {
    return {
      form: new Form({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        device_name: "web",
      }),
      message: "",
    };
  },

  methods: {
    onSubmit() {
      this.form
        .submitService(authService, "register")
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push({
              name: "registerVendor.show",
              query: { redirect: this.$route.query.redirect },
            });
          } else {
            this.$router.push({ name: "registerVendor.show" });
          }
        })
        .catch((error) => {
          this.message = error.message;
        });
    },
  },
};
</script>

<style></style>
